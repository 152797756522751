import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-3" }
const _hoisted_3 = { class: "menu-content d-flex align-items-center px-3" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "fw-bolder d-flex align-items-center fs-5" }
const _hoisted_6 = {
  href: "#",
  class: "fw-bold text-muted text-hover-primary fs-7"
}
const _hoisted_7 = { class: "menu-item px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(this.$store.getters.currentUser.user.userprofile.firstname) + " " + _toDisplayString(this.$store.getters.currentUser.user.userprofile.lastname), 1),
          _createElementVNode("a", _hoisted_6, _toDisplayString(this.$store.getters.currentUser.user.email), 1)
        ])
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "separator my-2" }, null, -1)),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.signOut())),
        class: "menu-link px-5"
      }, _toDisplayString(_ctx.$t("layout.header.logout")), 1)
    ])
  ]))
}