import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "kt_aside_menu_wrapper",
  ref: "scrollElRef",
  class: "hover-scroll-overlay-y my-5 my-lg-5",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-dependencies": "#kt_aside_logo, #kt_aside_footer",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-offset": "0",
  "data-kt-scroll-wrappers": "#kt_aside_menu"
}
const _hoisted_2 = {
  id: "#kt_header_menu",
  class: "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
  "data-kt-menu": "true"
}
const _hoisted_3 = { class: "menu-item" }
const _hoisted_4 = ["href", "onClick"]
const _hoisted_5 = { class: "menu-title" }
const _hoisted_6 = { class: "menu-item" }
const _hoisted_7 = { class: "menu-content pt-5 pb-2" }
const _hoisted_8 = { class: "menu-section text-muted text-uppercase fs-8 ls-1" }
const _hoisted_9 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_10 = ["href", "onClick"]
const _hoisted_11 = { class: "menu-title" }
const _hoisted_12 = {
  key: 1,
  class: "menu-item"
}
const _hoisted_13 = ["href", "onClick"]
const _hoisted_14 = { class: "menu-title" }
const _hoisted_15 = {
  key: 2,
  class: "menu-item"
}
const _hoisted_16 = ["href", "onClick"]
const _hoisted_17 = { class: "menu-title" }
const _hoisted_18 = {
  key: 3,
  class: "menu-item"
}
const _hoisted_19 = ["href", "onClick"]
const _hoisted_20 = { class: "menu-title" }
const _hoisted_21 = {
  key: 4,
  class: "menu-item"
}
const _hoisted_22 = ["href", "onClick"]
const _hoisted_23 = { class: "menu-title" }
const _hoisted_24 = {
  key: 5,
  class: "menu-item"
}
const _hoisted_25 = ["href", "onClick"]
const _hoisted_26 = { class: "menu-title" }
const _hoisted_27 = {
  key: 6,
  class: "menu-item"
}
const _hoisted_28 = ["href", "onClick"]
const _hoisted_29 = { class: "menu-title" }
const _hoisted_30 = { class: "menu-item" }
const _hoisted_31 = { class: "menu-content pt-5 pb-2" }
const _hoisted_32 = { class: "menu-section text-muted text-uppercase fs-8 ls-1" }
const _hoisted_33 = {
  key: 8,
  class: "menu-item"
}
const _hoisted_34 = { class: "menu-item" }
const _hoisted_35 = ["href", "onClick"]
const _hoisted_36 = { class: "menu-title" }
const _hoisted_37 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_38 = ["href", "onClick"]
const _hoisted_39 = { class: "menu-title" }
const _hoisted_40 = {
  key: 1,
  class: "menu-item"
}
const _hoisted_41 = ["href", "onClick"]
const _hoisted_42 = { class: "menu-title" }
const _hoisted_43 = {
  key: 2,
  class: "menu-item menu-accordion",
  "data-kt-menu-sub": "accordion",
  "data-kt-menu-trigger": "click"
}
const _hoisted_44 = { class: "menu-link" }
const _hoisted_45 = { class: "menu-title" }
const _hoisted_46 = { class: "menu-sub menu-sub-accordion" }
const _hoisted_47 = { class: "menu-item" }
const _hoisted_48 = ["href", "onClick"]
const _hoisted_49 = { class: "menu-title" }
const _hoisted_50 = { class: "menu-item" }
const _hoisted_51 = ["href", "onClick"]
const _hoisted_52 = { class: "menu-title" }
const _hoisted_53 = { class: "menu-item" }
const _hoisted_54 = { class: "menu-content pt-5 pb-2" }
const _hoisted_55 = { class: "menu-section text-muted text-uppercase fs-8 ls-1" }
const _hoisted_56 = { class: "menu-item" }
const _hoisted_57 = ["href", "onClick"]
const _hoisted_58 = { class: "menu-title" }
const _hoisted_59 = { class: "menu-item" }
const _hoisted_60 = ["href", "onClick"]
const _hoisted_61 = { class: "menu-title" }
const _hoisted_62 = { class: "menu-item" }
const _hoisted_63 = ["href", "onClick"]
const _hoisted_64 = { class: "menu-title" }
const _hoisted_65 = {
  class: "menu-item menu-accordion",
  "data-kt-menu-sub": "accordion",
  "data-kt-menu-trigger": "click"
}
const _hoisted_66 = { class: "menu-link" }
const _hoisted_67 = { class: "menu-title" }
const _hoisted_68 = { class: "menu-sub menu-sub-accordion" }
const _hoisted_69 = { class: "menu-item" }
const _hoisted_70 = ["href", "onClick"]
const _hoisted_71 = { class: "menu-title" }
const _hoisted_72 = { class: "menu-item" }
const _hoisted_73 = ["href", "onClick"]
const _hoisted_74 = { class: "menu-title" }
const _hoisted_75 = { class: "menu-item" }
const _hoisted_76 = ["href", "onClick"]
const _hoisted_77 = { class: "menu-title" }
const _hoisted_78 = { class: "menu-item" }
const _hoisted_79 = ["href", "onClick"]
const _hoisted_80 = { class: "menu-title" }
const _hoisted_81 = { class: "menu-item" }
const _hoisted_82 = ["href", "onClick"]
const _hoisted_83 = { class: "menu-title" }
const _hoisted_84 = {
  class: "menu-item menu-accordion",
  "data-kt-menu-sub": "accordion",
  "data-kt-menu-trigger": "click"
}
const _hoisted_85 = ["href", "onClick"]
const _hoisted_86 = {
  class: "menu-item menu-accordion",
  "data-kt-menu-sub": "accordion",
  "data-kt-menu-trigger": "click"
}
const _hoisted_87 = ["href", "onClick"]
const _hoisted_88 = {
  class: "menu-item menu-accordion",
  "data-kt-menu-sub": "accordion",
  "data-kt-menu-trigger": "click"
}
const _hoisted_89 = { class: "menu-link" }
const _hoisted_90 = { class: "menu-title" }
const _hoisted_91 = { class: "menu-sub menu-sub-accordion" }
const _hoisted_92 = { class: "menu-item" }
const _hoisted_93 = ["href", "onClick"]
const _hoisted_94 = { class: "menu-title" }
const _hoisted_95 = { class: "menu-item" }
const _hoisted_96 = ["href", "onClick"]
const _hoisted_97 = { class: "menu-title" }
const _hoisted_98 = { class: "menu-item" }
const _hoisted_99 = ["href", "onClick"]
const _hoisted_100 = { class: "menu-title" }
const _hoisted_101 = { class: "menu-item" }
const _hoisted_102 = ["href", "onClick"]
const _hoisted_103 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/dashboard/" }, {
          default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
            _createElementVNode("a", {
              class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
              href: href,
              onClick: navigate
            }, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "menu-icon" }, [
                _createElementVNode("i", { class: "fas fa-home fs-3" })
              ], -1)),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("pages.dashboard.title")), 1)
            ], 10, _hoisted_4)
          ]),
          _: 1
        })
      ]),
      _cache[35] || (_cache[35] = _createElementVNode("div", { class: "menu-item mt-3" }, [
        _createElementVNode("div", { class: "menu-content" }, [
          _createElementVNode("div", { class: "separator" })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("layout.aside.managerSection")), 1)
        ])
      ]),
      (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('drive') !== -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_router_link, { to: "/drive/" }, {
              default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                _createElementVNode("a", {
                  class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                  href: href,
                  onClick: navigate
                }, [
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "menu-icon" }, [
                    _createElementVNode("i", { class: "fas fa-folder-open fs-3" })
                  ], -1)),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("pages.dashboard.drive")), 1)
                ], 10, _hoisted_10)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('attendance') !== -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_router_link, { to: "/attendance-monitoring/" }, {
              default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                _createElementVNode("a", {
                  class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                  href: href,
                  onClick: navigate
                }, [
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "menu-icon" }, [
                    _createElementVNode("i", { class: "fas fa-chart-line fs-3" })
                  ], -1)),
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("pages.dashboard.attendanceMonitoring")), 1)
                ], 10, _hoisted_13)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('results') !== -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_router_link, { to: "/results/" }, {
              default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                _createElementVNode("a", {
                  class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                  href: href,
                  onClick: navigate
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "menu-icon" }, [
                    _createElementVNode("i", { class: "fas fa-poll fs-3" })
                  ], -1)),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("pages.dashboard.results")), 1)
                ], 10, _hoisted_16)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('roadmap') !== -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createVNode(_component_router_link, { to: "/roadmap/" }, {
              default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                _createElementVNode("a", {
                  class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                  href: href,
                  onClick: navigate
                }, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "menu-icon" }, [
                    _createElementVNode("i", { class: "fas fa-map-marked-alt fs-3" })
                  ], -1)),
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("pages.roadmap.title")), 1)
                ], 10, _hoisted_19)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('ideas_box') !== -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            _createVNode(_component_router_link, { to: "/ideas/" }, {
              default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                _createElementVNode("a", {
                  class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                  href: href,
                  onClick: navigate
                }, [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "menu-icon" }, [
                    _createElementVNode("i", { class: "fas fa-lightbulb fs-3" })
                  ], -1)),
                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("pages.ideas.title")), 1)
                ], 10, _hoisted_22)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('useful-training') !== -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _createVNode(_component_router_link, { to: "/useful-training/" }, {
              default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                _createElementVNode("a", {
                  class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                  href: href,
                  onClick: navigate
                }, [
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "menu-icon" }, [
                    _createElementVNode("i", { class: "fas fa-chalkboard-teacher fs-3" })
                  ], -1)),
                  _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("pages.usefulTraining.title")), 1)
                ], 10, _hoisted_25)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('survey') !== -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            _createVNode(_component_router_link, { to: "/surveys/" }, {
              default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                _createElementVNode("a", {
                  class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                  href: href,
                  onClick: navigate
                }, [
                  _cache[7] || (_cache[7] = _createElementVNode("span", { class: "menu-icon" }, [
                    _createElementVNode("i", { class: "fas fa-eye fs-3" })
                  ], -1)),
                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t("pages.surveys.title")), 1)
                ], 10, _hoisted_28)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (
                    this.$store.getters.currentUser.user.userprofile.user_type.position === 1 ||
                    this.$store.getters.currentUser.services.indexOf('users') !== -1 ||
                    this.$store.getters.currentUser.services.indexOf('entities') !== -1
                )
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "menu-item mt-3" }, [
              _createElementVNode("div", { class: "menu-content" }, [
                _createElementVNode("div", { class: "separator" })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t("layout.aside.respSection")), 1)
              ])
            ])
          ], 64))
        : _createCommentVNode("", true),
      (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('users') !== -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
            _createElementVNode("div", _hoisted_34, [
              _createVNode(_component_router_link, { to: "/users/" }, {
                default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                  _createElementVNode("a", {
                    class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                    href: href,
                    onClick: navigate
                  }, [
                    _cache[9] || (_cache[9] = _createElementVNode("span", { class: "menu-icon" }, [
                      _createElementVNode("i", { class: "fas fa-users fs-3" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t("pages.dashboard.users")), 1)
                  ], 10, _hoisted_35)
                ]),
                _: 1
              })
            ]),
            (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('entities') !== -1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                  _createVNode(_component_router_link, { to: "/entities/" }, {
                    default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                      _createElementVNode("a", {
                        class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                        href: href,
                        onClick: navigate
                      }, [
                        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "menu-icon" }, [
                          _createElementVNode("i", { class: "fas fa-sitemap fs-3" })
                        ], -1)),
                        _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.$t("pages.dashboard.entities")), 1)
                      ], 10, _hoisted_38)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('notifications') !== -1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                  _createVNode(_component_router_link, { to: "/notifications/" }, {
                    default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                      _createElementVNode("a", {
                        class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                        href: href,
                        onClick: navigate
                      }, [
                        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "menu-icon" }, [
                          _createElementVNode("i", { class: "fas fa-bell fs-3" })
                        ], -1)),
                        _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.$t("pages.dashboard.notifications")), 1)
                      ], 10, _hoisted_41)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (this.$store.getters.currentUser.user.userprofile.user_type.position === 1 || this.$store.getters.currentUser.services.indexOf('statistics') !== -1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                  _createElementVNode("span", _hoisted_44, [
                    _cache[12] || (_cache[12] = _createElementVNode("span", { class: "menu-icon" }, [
                      _createElementVNode("i", { class: "fas fa-chart-pie" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t("pages.statistics.title")), 1),
                    _cache[13] || (_cache[13] = _createElementVNode("span", { class: "menu-arrow" }, null, -1))
                  ]),
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, [
                      _createVNode(_component_router_link, { to: "/statistics/users/" }, {
                        default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                          _createElementVNode("a", {
                            class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                            href: href,
                            onClick: navigate
                          }, [
                            _cache[14] || (_cache[14] = _createElementVNode("span", { class: "menu-bullet" }, [
                              _createElementVNode("span", { class: "bullet bullet-dot" })
                            ], -1)),
                            _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.$t("pages.userstatistics.title")), 1)
                          ], 10, _hoisted_48)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_50, [
                      _createVNode(_component_router_link, { to: "/statistics/roadmaps/" }, {
                        default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                          _createElementVNode("a", {
                            class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                            href: href,
                            onClick: navigate
                          }, [
                            _cache[15] || (_cache[15] = _createElementVNode("span", { class: "menu-bullet" }, [
                              _createElementVNode("span", { class: "bullet bullet-dot" })
                            ], -1)),
                            _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.$t("pages.roadmapstatistics.title")), 1)
                          ], 10, _hoisted_51)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _cache[36] || (_cache[36] = _createElementVNode("div", { class: "menu-item mt-3" }, [
        _createElementVNode("div", { class: "menu-content" }, [
          _createElementVNode("div", { class: "separator" })
        ])
      ], -1)),
      (this.$store.getters.currentUser.user.userprofile.user_type.position === 1)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 9 }, [
            _createElementVNode("div", _hoisted_53, [
              _createElementVNode("div", _hoisted_54, [
                _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.$t("layout.aside.admin")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_56, [
              _createVNode(_component_router_link, { to: "/administration/settings/" }, {
                default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                  _createElementVNode("a", {
                    class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                    href: href,
                    onClick: navigate
                  }, [
                    _cache[16] || (_cache[16] = _createElementVNode("span", { class: "menu-icon" }, [
                      _createElementVNode("i", { class: "fas fa-cogs fs-3" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.$t("pages.settings.altTitle")), 1)
                  ], 10, _hoisted_57)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_59, [
              _createVNode(_component_router_link, { to: "/administration/access/" }, {
                default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                  _createElementVNode("a", {
                    class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                    href: href,
                    onClick: navigate
                  }, [
                    _cache[17] || (_cache[17] = _createElementVNode("span", { class: "menu-icon" }, [
                      _createElementVNode("i", { class: "fas fa-universal-access fs-3" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_61, _toDisplayString(_ctx.$t("pages.usersAccess.title")), 1)
                  ], 10, _hoisted_60)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_62, [
              _createVNode(_component_router_link, { to: "/administration/users/" }, {
                default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                  _createElementVNode("a", {
                    class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                    href: href,
                    onClick: navigate
                  }, [
                    _cache[18] || (_cache[18] = _createElementVNode("span", { class: "menu-icon" }, [
                      _createElementVNode("i", { class: "fas fa-users-cog fs-3" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_64, _toDisplayString(_ctx.$t("pages.dashboard.users")), 1)
                  ], 10, _hoisted_63)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_65, [
              _createElementVNode("span", _hoisted_66, [
                _cache[19] || (_cache[19] = _createElementVNode("span", { class: "menu-icon" }, [
                  _createElementVNode("i", { class: "fas fa-file-signature fs-3" })
                ], -1)),
                _createElementVNode("span", _hoisted_67, _toDisplayString(_ctx.$t("layout.aside.docsManagement")), 1),
                _cache[20] || (_cache[20] = _createElementVNode("span", { class: "menu-arrow" }, null, -1))
              ]),
              _createElementVNode("div", _hoisted_68, [
                _createElementVNode("div", _hoisted_69, [
                  _createVNode(_component_router_link, { to: "/administration/documents/documents/" }, {
                    default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                      _createElementVNode("a", {
                        class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                        href: href,
                        onClick: navigate
                      }, [
                        _cache[21] || (_cache[21] = _createElementVNode("span", { class: "menu-bullet" }, [
                          _createElementVNode("span", { class: "bullet bullet-dot" })
                        ], -1)),
                        _createElementVNode("span", _hoisted_71, _toDisplayString(_ctx.$t("pages.driveManagement.documents.title")), 1)
                      ], 10, _hoisted_70)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_72, [
                  _createVNode(_component_router_link, { to: "/administration/documents/categories/" }, {
                    default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                      _createElementVNode("a", {
                        class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                        href: href,
                        onClick: navigate
                      }, [
                        _cache[22] || (_cache[22] = _createElementVNode("span", { class: "menu-bullet" }, [
                          _createElementVNode("span", { class: "bullet bullet-dot" })
                        ], -1)),
                        _createElementVNode("span", _hoisted_74, _toDisplayString(_ctx.$t("pages.driveManagement.categories.title")), 1)
                      ], 10, _hoisted_73)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_75, [
              _createVNode(_component_router_link, { to: "/administration/roadmapcategory/" }, {
                default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                  _createElementVNode("a", {
                    class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                    href: href,
                    onClick: navigate
                  }, [
                    _cache[23] || (_cache[23] = _createElementVNode("span", { class: "menu-icon" }, [
                      _createElementVNode("i", { class: "fas fa-map-marked-alt fs-3" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_77, _toDisplayString(_ctx.$t("layout.aside.roadmapThemes")), 1)
                  ], 10, _hoisted_76)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_78, [
              _createVNode(_component_router_link, { to: "/administration/ideabox/" }, {
                default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                  _createElementVNode("a", {
                    class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                    href: href,
                    onClick: navigate
                  }, [
                    _cache[24] || (_cache[24] = _createElementVNode("span", { class: "menu-icon" }, [
                      _createElementVNode("i", { class: "fas fa-lightbulb fs-3" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_80, _toDisplayString(_ctx.$t("pages.ideas.title")), 1)
                  ], 10, _hoisted_79)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_81, [
              _createVNode(_component_router_link, { to: "/administration/usefultraining/" }, {
                default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                  _createElementVNode("a", {
                    class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                    href: href,
                    onClick: navigate
                  }, [
                    _cache[25] || (_cache[25] = _createElementVNode("span", { class: "menu-icon" }, [
                      _createElementVNode("i", { class: "fas fa-chalkboard-teacher fs-3" })
                    ], -1)),
                    _createElementVNode("span", _hoisted_83, _toDisplayString(_ctx.$t("pages.usefulTraining.title")), 1)
                  ], 10, _hoisted_82)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_84, [
              _createVNode(_component_router_link, { to: "/administration/results/" }, {
                default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                  _createElementVNode("a", {
                    class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                    href: href,
                    onClick: navigate
                  }, _cache[26] || (_cache[26] = [
                    _createElementVNode("span", { class: "menu-icon" }, [
                      _createElementVNode("i", { class: "fas fa-solid fa-binoculars fs-3" })
                    ], -1),
                    _createElementVNode("span", { class: "menu-title" }, "Résultats", -1)
                  ]), 10, _hoisted_85)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_86, [
              _createVNode(_component_router_link, { to: "/administration/campaigns/" }, {
                default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                  _createElementVNode("a", {
                    class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                    href: href,
                    onClick: navigate
                  }, _cache[27] || (_cache[27] = [
                    _createElementVNode("span", { class: "menu-icon" }, [
                      _createElementVNode("i", { class: "fas fa-solid fa-binoculars fs-3" })
                    ], -1),
                    _createElementVNode("span", { class: "menu-title" }, "Gestion des campagnes", -1)
                  ]), 10, _hoisted_87)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_88, [
              _createElementVNode("span", _hoisted_89, [
                _cache[28] || (_cache[28] = _createElementVNode("span", { class: "menu-icon" }, [
                  _createElementVNode("i", { class: "fas fa-eye fs-3" })
                ], -1)),
                _createElementVNode("span", _hoisted_90, _toDisplayString(_ctx.$t("layout.aside.surveys")), 1),
                _cache[29] || (_cache[29] = _createElementVNode("span", { class: "menu-arrow" }, null, -1))
              ]),
              _createElementVNode("div", _hoisted_91, [
                _createElementVNode("div", _hoisted_92, [
                  _createVNode(_component_router_link, { to: "/administration/surveys/list/" }, {
                    default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                      _createElementVNode("a", {
                        class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                        href: href,
                        onClick: navigate
                      }, [
                        _cache[30] || (_cache[30] = _createElementVNode("span", { class: "menu-bullet" }, [
                          _createElementVNode("span", { class: "bullet bullet-dot" })
                        ], -1)),
                        _createElementVNode("span", _hoisted_94, _toDisplayString(_ctx.$t("pages.surveys.title")), 1)
                      ], 10, _hoisted_93)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_95, [
                  _createVNode(_component_router_link, { to: "/administration/surveys/questions/" }, {
                    default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                      _createElementVNode("a", {
                        class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                        href: href,
                        onClick: navigate
                      }, [
                        _cache[31] || (_cache[31] = _createElementVNode("span", { class: "menu-bullet" }, [
                          _createElementVNode("span", { class: "bullet bullet-dot" })
                        ], -1)),
                        _createElementVNode("span", _hoisted_97, _toDisplayString(_ctx.$t("pages.surveys.questions.title")), 1)
                      ], 10, _hoisted_96)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_98, [
                  _createVNode(_component_router_link, { to: "/administration/surveys/question-tags/" }, {
                    default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                      _createElementVNode("a", {
                        class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                        href: href,
                        onClick: navigate
                      }, [
                        _cache[32] || (_cache[32] = _createElementVNode("span", { class: "menu-bullet" }, [
                          _createElementVNode("span", { class: "bullet bullet-dot" })
                        ], -1)),
                        _createElementVNode("span", _hoisted_100, _toDisplayString(_ctx.$t("pages.surveys.questiontags.title")), 1)
                      ], 10, _hoisted_99)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_101, [
                  _createVNode(_component_router_link, { to: "/administration/surveys/languages/" }, {
                    default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                      _createElementVNode("a", {
                        class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                        href: href,
                        onClick: navigate
                      }, [
                        _cache[33] || (_cache[33] = _createElementVNode("span", { class: "menu-bullet" }, [
                          _createElementVNode("span", { class: "bullet bullet-dot" })
                        ], -1)),
                        _createElementVNode("span", _hoisted_103, _toDisplayString(_ctx.$t("pages.surveys.language.title")), 1)
                      ], 10, _hoisted_102)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _cache[34] || (_cache[34] = _createElementVNode("div", { class: "menu-item" }, [
              _createElementVNode("div", { class: "menu-content" }, [
                _createElementVNode("div", { class: "separator mx-1 my-4" })
              ])
            ], -1))
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 512))
}