import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_3 = {
  key: 0,
  class: "text-end me-5"
}
const _hoisted_4 = {
  class: "d-flex align-items-center d-lg-none ms-2 me-n3",
  title: "Show header menu"
}
const _hoisted_5 = {
  class: "btn btn-icon btn-active-light-primary",
  id: "kt_header_menu_mobile_toggle"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (
                    this.$store.getters.currentSettings &&
                    this.$store.getters.currentSettings.en &&
                    this.$store.getters.currentSettings.fr &&
                    this.$route.name !== 'administration-settings'
                )
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.i18n && _ctx.i18n.locale === 'en')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLocale('fr'))),
                  class: "btn btn-icon btn-light"
                }, _cache[2] || (_cache[2] = [
                  _createElementVNode("img", {
                    alt: "Logo",
                    src: "/media/france.png",
                    class: "w-50"
                  }, null, -1)
                ])))
              : (_ctx.i18n && _ctx.i18n.locale === 'fr')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLocale('en'))),
                    class: "btn btn-icon btn-light"
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("img", {
                      alt: "Logo",
                      src: "/media/royaume-uni.png",
                      class: "w-50"
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "cursor-pointer symbol symbol-30px symbol-md-40px",
        "data-kt-menu-trigger": "click",
        "data-kt-menu-attach": "parent",
        "data-kt-menu-placement": "bottom-end",
        "data-kt-menu-flip": "bottom"
      }, [
        _createElementVNode("a", {
          href: "#",
          class: "btn btn-icon btn-primary"
        }, [
          _createElementVNode("i", { class: "fas fa-user-circle fs-2" })
        ])
      ], -1)),
      _createVNode(_component_KTUserMenu)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_inline_svg, { src: "/media/icons/duotune/text/txt001.svg" })
        ])
      ])
    ])
  ]))
}