import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "footer py-4 d-flex flex-lg-column",
  id: "kt_footer"
}
const _hoisted_2 = { class: "menu menu-gray-600 menu-hover-primary fw-bold order-1" }
const _hoisted_3 = { class: "menu-item" }
const _hoisted_4 = {
  href: "#",
  target: "_blank",
  class: "menu-link px-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex flex-column flex-md-row align-items-center justify-content-between", {
                'container-fluid': _ctx.footerWidthFluid,
                'container-xxl': !_ctx.footerWidthFluid,
            }])
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-dark order-2 order-md-1" }, [
        _createElementVNode("span", { class: "text-muted fw-bold me-1" }, "2021©"),
        _createElementVNode("a", {
          href: "https://keenthemes.com/",
          target: "_blank",
          class: "text-gray-800 text-hover-primary"
        }, "ECCHO by Obea")
      ], -1)),
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t("layout.footer.contactUs")), 1)
        ])
      ])
    ], 2)
  ]))
}