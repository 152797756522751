import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_4 = { class: "post d-flex flex-column-fluid" }
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "mb-1 text-dark" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.asideEnabled)
        ? (_openBlock(), _createBlock(_component_KTAside, {
            key: 0,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.subheaderDisplay)
            ? (_openBlock(), _createBlock(_component_KTToolbar, {
                key: 0,
                breadcrumbs: _ctx.breadcrumbs,
                title: _ctx.pageTitle
              }, null, 8, ["breadcrumbs", "title"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              id: "kt_content_container",
              class: _normalizeClass({
                            'container-fluid': _ctx.contentWidthFluid,
                            'container-xxl': !_ctx.contentWidthFluid,
                        })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "alert alert-primary d-flex align-items-center p-5",
                  key: notification.id
                }, [
                  _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fas fa-bell fs-2hx text-primary me-4" }, null, -1)),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h4", _hoisted_6, _toDisplayString(notification.name), 1),
                    _createElementVNode("span", null, _toDisplayString(notification.description), 1)
                  ]),
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.addUserNotification(notification.id)),
                    type: "button",
                    class: "position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto",
                    "data-bs-dismiss": "alert"
                  }, _cache[0] || (_cache[0] = [
                    _createElementVNode("i", { class: "fas fa-times fs-1 text-dark" }, [
                      _createElementVNode("span", { class: "path1" }),
                      _createElementVNode("span", { class: "path2" })
                    ], -1)
                  ]), 8, _hoisted_7)
                ]))
              }), 128)),
              _createVNode(_component_router_view)
            ], 2)
          ])
        ]),
        _createVNode(_component_KTFooter)
      ])
    ])
  ], 64))
}